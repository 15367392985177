:root {
    --brand-primary            : #1b365c;
    --brand-primary-lighten-10 : #274d83;
    --brand-primary-lighten-40 : #6e99d5;
    --brand-primary-darken-5   : #152a48;
    --brand-primary-darken-10  : #0f1f35;
    --brand-primary-darken-12  : #0d1a2d;
    --brand-primary-opacity-25 : #1b365c40;
    --brand-primary-opacity-50 : #1b365c80;

    --brand-secondary          : #47ddfc;

    --highlight                : #9bb9e2;
    --highlight-tint           : #e7eef8;

    --navbar-height            : 60px;
    --navbar-brand-width       : 166px;
    --navbar-brand-height      : 16px;
    --navbar-brand-margin-left : 16px;
    --navbar-brand-logo-size   : auto;
    --navbar-brand-logo        : url('/assets/images/clients/polaris/navbar-brand-logo.png');
    --navbar-brand-logo-mobile : url('/assets/images/clients/polaris/navbar-brand-logo.png');

    --logo-gap                 : 24px;
    --logo-bg-size             : auto;
    --login-logo-height        : auto;
    --login-logo-width         : 200px;
    --login-logo               : url('/assets/images/PoweredByShift.png');
    --login-brand-logo         : url('/assets/images/clients/polaris/login_logo.png');
    --login-brand-logo-width   : 300px;
    --login-brand-logo-height  : 105px;

    --sidebar-width            : 220px;
    --mobile-sidebar-width     : 220px;

    --sidebar-bg               : #1b365c;
    --sidebar-bg-darken-3      : #182f50;
    --sidebar-bg-darken-5      : #152a48;
    --sidebar-bg-darken-10     : #0f1f35;
    --sidebar-bg-lighten-5     : #214270;

    --combined-bar-green       : #18b52d;
    --combined-bar-blue        : #224197;
    --combined-bar-yellow      : #f9bb11;
    --combined-bar-red         : #c51230;
}